import React from 'react';
import { Backdrop, CircularProgress, Paper, Table, TableContainer } from '@mui/material';

interface SectionTableProps {
  children?: React.JSX.Element | React.JSX.Element[];
  loading?: boolean;
}

export default function SectionTable({ children, loading = false }: SectionTableProps) {
  return (
    <TableContainer sx={{ position: 'relative' }} component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small">
        {children}
      </Table>
      <Backdrop open={loading} sx={{ position: 'absolute', color: '#fff', opacity: '0.2 !important' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </TableContainer>
  );
}
