import React, { ReactNode, useEffect, useState } from 'react';
import { GoogleMap, InfoWindowF, Libraries, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import { useGetConfigQuery } from '../state/api';
import { ContractingPartyCard } from '../scenes/documents/map/ContractingPartyCard';
import { ContractingPartyMarker } from '../scenes/documents/map/model';
import EmptyState from './EmptyState';
import { Map } from '@mui/icons-material';
import { Skeleton } from '@mui/material';

export interface MapPoint<T> {
  id: string;
  lat: number;
  lng: number;
  data: T;
}

interface MapViewProps {
  points: MapPoint<unknown>[];
  isLoading: boolean;
}

const containerStyle = {
  width: '100%',
  height: '600px',
  padding: 0,
  margin: 0
};

interface GoogleMapsLoaderProps {
  apiKey: string;
  children: ReactNode;
}

const mapLibraries: Libraries = ['visualization'];

const GoogleMapsLoader: React.FC<GoogleMapsLoaderProps> = ({ apiKey, children }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    libraries: mapLibraries
  });
  if (loadError) {
    return <div>Error loading maps</div>;
  }
  if (!isLoaded) {
    return <div>Loading Maps...</div>;
  }
  return <>{children}</>;
};

const MapView = ({ points, isLoading: isPointLoading }: MapViewProps) => {
  const { data: config, isError, isLoading } = useGetConfigQuery();
  const googleMapsApiKey = config?.googleMapsApiKey || '';
  const [, /*mapRef*/ setMapRef] = useState<google.maps.Map>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [infoWindowData, setInfoWindowData] = useState<MapPoint<unknown>>(null);
  const [mapRenderId, setMapRenderId] = useState<number>(0);

  const onMapLoad = (map: google.maps.Map) => {
    setMapRef(map);
    const bounds = new google.maps.LatLngBounds();
    points?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
    map.fitBounds(bounds);
  };

  const handleMarkerClick = (point: MapPoint<unknown>) => {
    //mapRef?.panTo({ lat: point.lat, lng: point.lng });
    if (isOpen && point.id === infoWindowData.id) {
      setInfoWindowData(null);
      setIsOpen(false);
    } else {
      setInfoWindowData(point);
      setIsOpen(true);
    }
  };

  useEffect(() => {
    setMapRenderId((prevState) => prevState + 1);
  }, [points]);

  if (isLoading || isPointLoading) {
    return <Skeleton variant="rectangular" width="100%" height={600} />;
  }

  if (isError) {
    return <div>Error showing map</div>;
  }

  if (points.length === 0) {
    return (
      <EmptyState
        title="No contracting parties found"
        description="If you upload documents with contracting parties, you will see them here."
        icon={<Map />}
      />
    );
  }

  return (
    <GoogleMapsLoader apiKey={googleMapsApiKey}>
      <GoogleMap
        key={mapRenderId} /* force reload of component if points change */
        onLoad={onMapLoad}
        mapContainerStyle={containerStyle}
        zoom={2}
        options={{
          streetViewControl: false,
          mapTypeControl: false
        }}
      >
        {points.map((point) => (
          <MarkerF
            key={point.id}
            position={{ lat: point.lat, lng: point.lng }}
            icon={'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'}
            onClick={() => {
              handleMarkerClick(point);
            }}
          >
            {isOpen && infoWindowData.id === point.id && (
              <InfoWindowF
                onCloseClick={() => {
                  setIsOpen(false);
                }}
              >
                <ContractingPartyCard point={infoWindowData as MapPoint<ContractingPartyMarker>} />
              </InfoWindowF>
            )}
          </MarkerF>
        ))}
      </GoogleMap>
    </GoogleMapsLoader>
  );
};

export default MapView;
