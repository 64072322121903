import { Description } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmptyState from '../../components/EmptyState';
import { MapPoint } from '../../components/MapView';
import SectionBody from '../../components/SectionBody';
import {
  useLazyGetExtractionsByDocumentIdQuery,
  useLazyGetKpisByDocumentIdsQuery,
  useLazySearchDocumentsQuery
} from '../../state/api';
import {
  finishDocumentUpload,
  selectDocumentsInProgresNotCreated,
  selectDocumentsInProgress,
  selectDocumentsInProgressById,
  updateDocumentUpload
} from '../../state/documentProgressSlice';
import {
  DocumentId,
  isDateAnnotation,
  isStringAnnotation,
  StandardLabel,
  TextExtraction
} from '../../types/annotation';
import { DocumentDetails, DocumentSearchQuery, SortOrder } from '../../types/document';
import DocumentsKpiView from './DocumentsKpiView';
import DocumentsOverview from './DocumentsOverview';
import MapComponent from './map';
import { ContractingPartyMarker, convert } from './map/model';
import UploadArea from './UploadArea';

export interface DocumentWithExtractions {
  document: DocumentDetails;
  extractions: TextExtraction[];
}

export interface DocumentNotCreated {
  id: string;
  name: string;
  uploadStarted: string;
}

export interface DocumentInProgress {
  id: string;
  progress: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ display: value === index ? 'block' : 'none' }}>{children}</Box>
    </div>
  );
}

const getInitialSearch = (): string => {
  return localStorage.getItem('searchFilter') || '';
};

export default function Documents() {
  const MAX_PROGRESS = 97;
  const dispatch = useDispatch();
  const documentsInProgress = useSelector(selectDocumentsInProgress);
  const documentsInProgressById = useSelector(selectDocumentsInProgressById);
  const documentsNotCreated = useSelector(selectDocumentsInProgresNotCreated);

  const [value, setValue] = useState(0);
  const [search, setSearch] = useState(getInitialSearch());
  const [sortedBy] = useState<string>('createdAt');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [contractsOnly, setContractsOnly] = React.useState<boolean>(true);

  const [documents, setDocuments] = useState<DocumentWithExtractions[]>([]);

  const [searchDocuments, result] = useLazySearchDocumentsQuery();
  const [getKpis] = useLazyGetKpisByDocumentIdsQuery();
  const [triggerGetExtractionsByDocumentId] = useLazyGetExtractionsByDocumentIdQuery();
  const [loading, setLoading] = useState<boolean>(result.isUninitialized);

  const handleChange = (_: React.ChangeEvent<object>, newValue: number) => {
    setValue(newValue);
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem('searchFilter', event.target.value);
    setSearch(event.target.value);
  };
  const handleChangeSortOrder = () => {
    setSortOrder((prev) => {
      if (prev === 'asc') {
        return 'desc';
      } else {
        return 'asc';
      }
    });
  };

  // Fake increasing progress for documents in progress
  useEffect(() => {
    const progressId = setInterval(() => {
      documentsInProgress.forEach((documentInProgress) => {
        if (documentInProgress.progress < MAX_PROGRESS) {
          dispatch(
            updateDocumentUpload({ id: documentInProgress.id, progress: documentInProgress.progress + Math.random() })
          );
        }
      });
      if (documentsInProgress.length === 0 || documentsInProgress.every((d) => d.progress > MAX_PROGRESS)) {
        clearInterval(progressId);
      }
    }, 1000);
    return () => clearInterval(progressId);
  }, [documentsInProgress]);

  // Replace document not created with document in progress
  useEffect(() => {
    if (documentsNotCreated.length > 0) {
      const existingNames = documents.map((d) => d.document.name);
      const notCreatedDupe = documentsNotCreated.find((d) => existingNames.includes(d.name));
      if (notCreatedDupe) {
        dispatch(finishDocumentUpload({ id: notCreatedDupe.id }));
      }
    }
  }, [documentsNotCreated, documents]);

  useEffect(() => {
    const q: DocumentSearchQuery = {
      query: '', // use 'search' when not filtering in ui anymore
      onlyContracts: contractsOnly,
      deleted: false,
      sortedBy: 'createdAt',
      sortOrder: sortOrder
    };

    searchDocuments(q);
  }, [contractsOnly, sortOrder]);

  useEffect(() => {
    const data = Array.from(result.data ?? []);
    setLoading(result.isLoading || result.isUninitialized);

    setDocuments((docs) => {
      const updated = [];

      data.forEach((n) => {
        const existing = docs.find((e) => e.document.id == n.id);
        if (existing) {
          updated.push({ document: n, extractions: existing.extractions });
        } else {
          updated.push({ document: n, extractions: [] });
        }
      });

      return updated;
    });

    const documentIds = data.map((doc) => doc.id);
    if (documentIds.length > 0) {
      getKpis(documentIds).then((result) => {
        result.data.forEach((kpi) => {
          console.log(`Kpi: ${kpi.name}`);
        });
      });
    }

    for (const doc of data) {
      triggerGetExtractionsByDocumentId(doc.id).then((extractionResult) => {
        const extractions = extractionResult.data;
        setDocuments((docs) =>
          docs.map((d) => {
            if (d.document.id === doc.id) {
              return { document: d.document, extractions: extractions };
            } else {
              return d;
            }
          })
        );
      });
    }
  }, [result]);

  const withExtractionsThatContains = (extractions: TextExtraction[], filter: string): boolean => {
    if (extractions?.length > 0) {
      // Only Extractions of StandardLabel 'supplier' are considered (for now)
      const supplier = normalisedExtractionValue(extractions.find((e) => e.labelName === StandardLabel.Supplier));
      return normalizeSupplier(supplier).toLowerCase().includes(filter);
    }
    return false;
  };

  const filteredDocuments: DocumentWithExtractions[] = useMemo(() => {
    if (search.length > 0) {
      return documents.filter(
        (doc) =>
          doc.document.name.toLowerCase().includes(search.toLowerCase()) ||
          withExtractionsThatContains(doc.extractions, search.toLowerCase())
      );
    } else {
      return documents;
    }
  }, [documents, search]);

  const handleChangeInContractsOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContractsOnly(event.target.checked);
  };

  const mapPoints: MapPoint<ContractingPartyMarker>[] = useMemo(() => {
    if (filteredDocuments.length > 0) {
      const partyPoints = filteredDocuments.map((doc) => {
        return convert(doc.document)
          .filter((partyMarker) => partyMarker.location)
          .map((partyMarker) => {
            return {
              id: partyMarker.extractionId,
              lat: partyMarker.location.lat,
              lng: partyMarker.location.lng,
              data: partyMarker
            };
          });
      });
      return partyPoints.flat();
    } else {
      return [];
    }
  }, [filteredDocuments]);

  function normalizeSupplier(name: string): string {
    if (name) {
      let result = name.replace(/\s+/g, ' ');
      result = result.replace(/[.,]/g, '');
      result = result.replace(/[()]/g, '');
      return result.toUpperCase();
    } else {
      return '';
    }
  }

  function normalisedExtractionValue(extraction: TextExtraction): string {
    if (extraction && isStringAnnotation(extraction)) {
      return extraction.value.string;
    } else if (extraction && isDateAnnotation(extraction)) {
      return extraction.value.date ? extraction.value.date.toString() : '';
    } else {
      return '';
    }
  }

  const extractionValue = (document: DocumentWithExtractions, label: StandardLabel) => {
    if (document.extractions && document.extractions.length > 0) {
      const docExtractions = document.extractions;
      if (docExtractions) {
        const extraction = docExtractions.find((extraction) => extraction.labelName === label);
        if (extraction) {
          return normalisedExtractionValue(extraction);
        }
      } else {
        return '';
      }
    }
  };

  const getExtractionValue = (documentId: DocumentId, label: StandardLabel): string => {
    const document = filteredDocuments.find((d) => d.document.id === documentId);

    const value = extractionValue(document, label);

    if (label === StandardLabel.Supplier) {
      return normalizeSupplier(value);
    }

    return value;
  };

  return (
    <>
      <SectionBody>
        <TextField
          placeholder="Search in documents"
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          size="medium"
          type="search"
          InputProps={{
            sx: { borderRadius: '3rem', backgroundColor: 'background.paper' },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          sx={{
            alignSelf: 'center',
            width: '100%',
            maxWidth: '42rem'
          }}
        />
        <FormGroup sx={{ alignSelf: 'center' }}>
          <FormControlLabel
            control={<Switch checked={contractsOnly} onChange={handleChangeInContractsOnly} />}
            label="Contracts only"
          />
        </FormGroup>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Overview" />
            <Tab label="Map View" />
            <Tab label="KPI View" />
          </Tabs>
          <UploadArea onProcessStarted={() => {}} />
        </Box>
        <TabPanel value={value} index={0}>
          {documents.length === 0 && documentsNotCreated.length === 0 && !loading ? (
            <EmptyState
              title="No documents found"
              description="Upload a document to get started"
              icon={<Description />}
            />
          ) : (
            <DocumentsOverview
              documents={filteredDocuments}
              documentsInProgressById={documentsInProgressById}
              documentsNotCreated={documentsNotCreated}
              isLoading={loading}
              getExtractionValue={getExtractionValue}
              sortedBy={sortedBy}
              sortOrder={sortOrder}
              onChangeSortOrder={handleChangeSortOrder}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MapComponent mapPoints={mapPoints} isLoading={loading} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <DocumentsKpiView documents={filteredDocuments} isLoading={loading} getExtractionValue={getExtractionValue} />
        </TabPanel>
      </SectionBody>
    </>
  );
}
