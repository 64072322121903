import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TextAnnotationType, TextExtraction } from '../../types/annotation';
import { DocumentFull, DocumentPage } from '../../types/document';
import DocumentPageSection from './DocumentPageSection';
import EmptyState from '../../components/EmptyState';
import { Description } from '@mui/icons-material';

interface DocumentProps {
  document: DocumentFull;
  annotations: TextExtraction[];
}

const DocumentPreview = ({ document, annotations }: DocumentProps) => {
  const [selectedTypes = [TextAnnotationType.EXTRACTION] /*,setSelectedTypes */] = useState<TextAnnotationType[]>([
    TextAnnotationType.EXTRACTION
  ]);
  const [pages, setPages] = useState<DocumentPage[]>(document.pages.slice(0, 2));
  const [hasMorePages, setHasMorePages] = useState<boolean>(document.pages.length > 2);

  useEffect(() => {
    if (annotations) {
      /* annotations.forEach((annotation) => {
        if (annotation.contractingParty) {
          triggerGetContractingPartyQuery({ documentId: documentId, partyId: annotation.contractingParty }).then(
            (result) => {
              if (result.data) {
                setParties((prevParties) => {
                  const updatedParties = new Map(prevParties);
                  updatedParties.set(annotation.contractingParty, result.data);
                  return updatedParties;
                });
              }
            }
          );
        }
      });*/
    }
  }, [annotations]);

  // const toggleType = (type: TextAnnotationType) => {
  //   if (selectedTypes.includes(type)) {
  //     setSelectedTypes(selectedTypes.filter((t) => t !== type));
  //   } else {
  //     setSelectedTypes([...selectedTypes, type]);
  //   }
  // };
  const loadMorePages = () => {
    setPages(pages.concat(document.pages.slice(pages.length, pages.length + 2)));
    setHasMorePages(document.pages.length > pages.length);
  };

  return (
    <InfiniteScroll
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem'
      }}
      dataLength={pages.length}
      next={loadMorePages}
      hasMore={hasMorePages}
      scrollableTarget="tabpanel-preview"
      loader={null}
    >
      {/*{Object.keys(TextAnnotationType).map((type) => (*/}
      {/*  <FormGroup key={type}>*/}
      {/*    <FormControlLabel*/}
      {/*      control={<Checkbox onChange={() => toggleType(TextAnnotationType[type])} />}*/}
      {/*      label={type.toString()}*/}
      {/*    />*/}
      {/*  </FormGroup>*/}
      {/*))}*/}
      {pages.length === 0 && (
        <EmptyState
          title="Document has no preview."
          description="This document has probably not been processed yet."
          icon={<Description />}
        />
      )}
      {pages.map((page) => (
        <DocumentPageSection
          key={page.page}
          documentId={document.details.documentId}
          page={page}
          showAnnotations={selectedTypes}
          totalPages={document.pages.length}
        />
      ))}
    </InfiniteScroll>
  );
};

export default DocumentPreview;
