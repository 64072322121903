import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

interface ConfirmDialogProps {
  title?: string;
  type?: 'info' | 'warning' | 'error' | 'success';
  open: boolean;
  confirmButtonText?: string;
  fullscreen?: boolean;
  children: React.ReactNode;
  onConfirm: () => void;
  onClose: () => void;
}

export default function ConfirmDialog({
  title,
  open,
  type = 'error',
  confirmButtonText,
  children,
  onConfirm,
  onClose
}: ConfirmDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      role="alertdialog"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title ?? 'Are you sure?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit" variant="text">
          Cancel
        </Button>
        <Button onClick={onConfirm} color={type} variant="contained">
          {confirmButtonText ?? 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
