import { CompareTwoTone } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Snackbar, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '../../components/ConfirmDialog';
import CreateButton from '../../components/CreateButton';
import EmptyState from '../../components/EmptyState';
import SectionBody from '../../components/SectionBody';
import SectionHeader from '../../components/SectionHeader';
import SectionTable from '../../components/SectionTable';
import SkeletonTableBody from '../../components/SkeletonTableBody';
import { useDeleteMirrorByIdMutation, useGetMirrorsQuery, usePostMirrorMutation } from '../../state/api';

export default function Mirrors() {
  const navigate = useNavigate();

  const { data: mirrors = [], isLoading: isMirrorsLoading } = useGetMirrorsQuery();
  const [createMirror, { isLoading: createMirrorLoading }] = usePostMirrorMutation();
  const [deleteMirror] = useDeleteMirrorByIdMutation();
  const [deleteMirrorId, setDeleteMirrorId] = useState<string>('');
  const [deleteMessage, setDeleteMessage] = useState<string>('');

  const handleClickAddMirror = async () => {
    try {
      const mirror = await createMirror({
        name: 'New Mirror'
      }).unwrap();

      if (mirror) {
        navigate(`/mirrors/${mirror.id}`);
      }
    } catch (error) {
      setDeleteMessage(`creating new mirror failed`);
    }
  };

  const handleShowDeleteDialog = (event, id: string) => {
    event.stopPropagation();
    setDeleteMirrorId(id);
  };

  const handleDeleteCancel = () => {
    setDeleteMirrorId('');
  };

  const handleRemoveDeleteMessage = () => {
    setDeleteMessage('');
  };

  const handleDeleteMirror = async () => {
    try {
      await deleteMirror(deleteMirrorId).unwrap();
      setDeleteMessage(`Mirror deleted`);
      setDeleteMirrorId('');
    } catch (error) {
      console.error('Failed to delete mirror: ', error);
    }
  };

  const renderBody = () => {
    if (!isMirrorsLoading && mirrors.length === 0) {
      return (
        <EmptyState
          title="No mirrors found"
          description="No mirrors created yet. Create one to get started"
          icon={<CompareTwoTone />}
        />
      );
    }
    return (
      <SectionTable>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isMirrorsLoading ? (
            <SkeletonTableBody rows={5} columns={3} />
          ) : (
            mirrors.map((mirror) => (
              <TableRow
                sx={{ cursor: 'pointer' }}
                key={mirror.id}
                hover
                onClick={() => navigate(`/mirrors/${mirror.id}`)}
              >
                <TableCell>{mirror.name}</TableCell>
                <TableCell>{mirror.status}</TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                    <IconButton aria-label="delete" onClick={(e) => handleShowDeleteDialog(e, mirror.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </SectionTable>
    );
  };

  return (
    <>
      <SectionHeader title="">
        <CreateButton title="Create mirror" onClick={handleClickAddMirror} disabled={createMirrorLoading} />
      </SectionHeader>
      <SectionBody>{renderBody()}</SectionBody>

      <ConfirmDialog
        title="Delete mirror"
        open={!!deleteMirrorId}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteMirror}
      >
        Do you really want to delete this mirror?
      </ConfirmDialog>

      <Snackbar
        open={deleteMessage.length > 0}
        autoHideDuration={6000}
        message={deleteMessage}
        onClose={handleRemoveDeleteMessage}
      />
    </>
  );
}
