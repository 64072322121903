import DeleteIcon from '@mui/icons-material/Delete';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map';
import {
  Box,
  IconButton,
  LinearProgress,
  Snackbar,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material';
import dayjs from 'dayjs';
import localeEn from 'dayjs/locale/en';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '../../components/ConfirmDialog';
import SectionTable from '../../components/SectionTable';
import SkeletonTableBody from '../../components/SkeletonTableBody';
import { useDeleteDocumentByIdMutation } from '../../state/api';
import { DocumentId, StandardLabel, TextExtraction } from '../../types/annotation';
import { DocumentDetails, SortOrder } from '../../types/document';
import { getShortenedFilename } from '../../utils/string.utils';
import { DocumentInProgress, DocumentNotCreated, DocumentWithExtractions } from './Documents';

interface DocumentListProps {
  isLoading: boolean;
  documents: DocumentWithExtractions[];
  documentsNotCreated: DocumentNotCreated[];
  documentsInProgressById: Record<DocumentId, DocumentInProgress>;
  getExtractionValue: (documentId: DocumentId, label: StandardLabel) => string;
  sortedBy: string;
  sortOrder: SortOrder;
  onChangeSortOrder: () => void;
}

const DocumentNotInitiatedRows = ({
  documents
}: {
  documents: { id: string; name?: string; uploadedAt: string }[];
}) => {
  return documents.map(({ id, name, uploadedAt }) => (
    <React.Fragment key={id}>
      <TableRow sx={{ height: '56px' }}>
        <TableCell>{uploadedAt}</TableCell>
        <TableCell colSpan={7}>{getShortenedFilename(name, 30)}</TableCell>
      </TableRow>
      <TableRow sx={{ height: '4px' }}>
        <TableCell sx={{ padding: 0 }} colSpan={7}>
          <LinearProgress variant="indeterminate" />
        </TableCell>
      </TableRow>
    </React.Fragment>
  ));
};

const DocumentsOverview = ({
  documents,
  isLoading,
  getExtractionValue,
  documentsInProgressById,
  documentsNotCreated,
  sortedBy,
  sortOrder,
  onChangeSortOrder
}: DocumentListProps) => {
  const navigate = useNavigate();
  const [deleteDocument] = useDeleteDocumentByIdMutation();
  const [documentToDelete, setDocumentToDelete] = useState<DocumentWithExtractions | undefined>(undefined);
  const [deleteMessage, setDeleteMessage] = useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

  const hasLocation = (text: DocumentDetails) => {
    return text.parties.some((t) => t.location && t.location.lat > 0);
  };

  const countKpis = (extractions: TextExtraction[]): number => {
    return extractions ? extractions.filter((extraction: TextExtraction) => extraction.labelName === 'Kpi').length : 0;
  };

  const handleChangeSortOrder = () => {
    onChangeSortOrder();
  };

  const handleShowDeleteDialog = (event: React.MouseEvent, doc: DocumentWithExtractions) => {
    event.stopPropagation();
    setOpenDeleteDialog(true);
    setDocumentToDelete(doc);
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
    // Animation needs to finish
    setTimeout(() => {
      setDocumentToDelete(undefined);
    }, 200);
  };

  const handleRemoveDeleteMessage = () => {
    setDeleteMessage('');
  };

  const handleDeleteDocument = async () => {
    try {
      await deleteDocument(documentToDelete.document.id).unwrap();
      setDeleteMessage(`Document deleted: ${documentToDelete.document.name}`);
      setOpenDeleteDialog(false);
      setTimeout(() => {
        setDocumentToDelete(undefined);
      }, 200);
    } catch (error) {
      console.error('Failed to delete document: ', error);
    }
  };

  const daysago = (postDate: Date | string) => {
    dayjs.extend(relativeTime).locale(localeEn);
    return dayjs(postDate).fromNow();
  };

  return (
    <>
      <SectionTable>
        <TableHead>
          <TableRow>
            <TableCell sortDirection={sortOrder}>
              <TableSortLabel active={sortedBy === 'createdAt'} direction={sortOrder} onClick={handleChangeSortOrder}>
                Uploaded
              </TableSortLabel>
            </TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Name</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Counterpart</TableCell>
            <TableCell sx={{ whiteSpace: 'nowrap' }}>Start Date</TableCell>
            <TableCell>
              <MapIcon />
            </TableCell>
            <TableCell>KPIs</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortOrder === 'desc' && (
            <DocumentNotInitiatedRows
              documents={documentsNotCreated.map((d) => ({ ...d, uploadedAt: daysago(d.uploadStarted) }))}
            ></DocumentNotInitiatedRows>
          )}
          {isLoading && <SkeletonTableBody rows={5} columns={7} />}
          {!isLoading &&
            documents &&
            documents.map((docWithExtractions) => {
              const doc = docWithExtractions.document;
              return (
                <React.Fragment key={doc.id}>
                  <TableRow
                    key={doc.id}
                    sx={{ cursor: 'pointer', height: '56px' }}
                    hover
                    onClick={() => navigate(`/documents/${doc.id}`)}
                  >
                    <TableCell>{daysago(doc.updatedAt)}</TableCell>
                    <TableCell>{getShortenedFilename(doc.name, 30)}</TableCell>
                    <TableCell>{getExtractionValue(doc.id, StandardLabel.Supplier)}</TableCell>
                    <TableCell>{getExtractionValue(doc.id, StandardLabel.StartDate)}</TableCell>
                    <TableCell>
                      {hasLocation(doc) ? <LocationOnIcon color="primary" /> : <LocationOffIcon color="disabled" />}
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{countKpis(docWithExtractions.extractions)}</TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                        <IconButton aria-label="delete" onClick={(e) => handleShowDeleteDialog(e, docWithExtractions)}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                  {documentsInProgressById[doc.id]?.progress < 100 && (
                    <TableRow sx={{ height: '4px' }}>
                      <TableCell sx={{ padding: 0 }} colSpan={7}>
                        <LinearProgress value={documentsInProgressById[doc.id].progress} variant="determinate" />
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              );
            })}
          {sortOrder === 'asc' && (
            <DocumentNotInitiatedRows
              documents={documentsNotCreated.map((d) => ({ ...d, uploadedAt: daysago(d.uploadStarted) }))}
            ></DocumentNotInitiatedRows>
          )}
        </TableBody>
      </SectionTable>

      <ConfirmDialog
        title="Delete document"
        open={openDeleteDialog}
        onClose={handleDeleteCancel}
        onConfirm={handleDeleteDocument}
      >
        Do you really want to delete &quot;{documentToDelete?.document.name}&quot;?
      </ConfirmDialog>

      <Snackbar
        open={deleteMessage.length > 0}
        autoHideDuration={3000}
        message={deleteMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={handleRemoveDeleteMessage}
      />
    </>
  );
};

export default DocumentsOverview;
